<template>
  <section class="main-section bg-white has-no-scroll">
    <main-section-header :title="title[currentCompany]" />
    <div class="container">
      <subtitle subtitle="설비 정보" />
      <ul class="register-list">
        <detail-list-row
          :is-register="true"
          v-for="(equipInfo, index) in equipmentInfo"
          :key="index"
          :info="equipInfo"
          :inputKey="equipInfo.key"
          :input-value="equipmentData[equipInfo.key]"
          :maxlength="equipInfo.maxlength"
          :placeholder="equipInfo.placeholder"
          :dropdown-list="dropdownList[equipInfo.key]"
          @saveChangeValue="onSaveInputValue"
          @setSelectedIdList="onSetSelectedIdList"
        >
          <span slot="label" class="label" :class="{ 'label-required': equipInfo.isRequired }">
            {{ equipInfo.label }}
            <template v-if="equipInfo.additionalInfo">
              <button class="tool-tip-button" @click.stop="toggleToolTip(equipInfo.key)">
                <vue-material-icon name="info" :size="12" />
                <span class="tool-tip" :class="{ 'tool-tip-show': isShowToolTip === equipInfo.key }">
                  {{ equipInfo.additionalInfo }}
                </span>
              </button>
            </template>
          </span>
        </detail-list-row>
      </ul>
      <template v-if="isManufacturerComp">
        <subtitle subtitle="납품 정보" />
        <ul class="register-list">
          <detail-list-row
            :is-register="true"
            v-for="(deliveryInfo, index) in deliveryEquipInfo"
            :key="index"
            :info="deliveryInfo"
            :inputKey="deliveryInfo.key"
            :input-value="equipmentData[deliveryInfo.key]"
            :placeholder="deliveryInfo.placeholder"
            :dropdown-list="dropdownList[deliveryInfo.key]"
            @saveChangeValue="onSaveInputValue"
            @setSelectedIdList="onSetSelectedIdList"
          >
            <span slot="label" class="label" :class="{ 'label-required': deliveryInfo.isRequired }">
              {{ deliveryInfo.label }}
              <template v-if="deliveryInfo.additionalInfo">
                <button class="tool-tip-button" @click.stop="toggleToolTip(deliveryInfo.key)">
                  <vue-material-icon name="info" :size="12" />
                  <span class="tool-tip" :class="{ 'tool-tip-show': isShowToolTip === deliveryInfo.key }">
                    {{ deliveryInfo.additionalInfo }}
                  </span>
                </button>
              </template>
            </span>
          </detail-list-row>
        </ul>
      </template>
      <list-bottom-button-box>
        <button-basic slot="button" color="gray-border-1" :width="120" @click="onMoveEquipmentList">취소</button-basic>
        <button-basic
          slot="button"
          color="primary"
          :width="120"
          :disabled="!isFilledAllRequiredField"
          @click="onSaveEquipment"
        >
          등록
        </button-basic>
      </list-bottom-button-box>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Subtitle from '@/component/subtitle/Subtitle';
import DetailListRow from '@/component/detailListRow/DetailListRow';
import ListBottomButtonBox from '@/component/list/ListBottomButtonBox';
import EqpManufacturerInfo from './equipmentRegisterData/ManufacturerInfo.json';
import EqpFactoryInfo from './equipmentRegisterData/FactoryInfo.json';
import EqpManufacturerDeliveryInfo from './equipmentRegisterData/ManufacturerDeliveryInfo.json';

export default {
  name: 'EquipmentRegister',
  props: [],
  data() {
    return {
      title: {
        M: '설비 등록',
        F: '자체 설비 등록',
      },
      equipmentInfo: '',
      deliveryEquipInfo: EqpManufacturerDeliveryInfo,
      equipmentData: {
        modelName: '',
        serialNumber: null,
        equipmentName: '',
        equipmentGroupId: 0,
        equipmentManagerIds: [],
        mainManagerUserId: 0,
        subManagerUserId: 0,
        sensorIds: [],
        factoryId: 0,
        companyId: 0,
      },
      dropdownList: {
        equipmentGroupId: [],
        equipmentManagerIds: [],
        mainManagerUserId: [],
        subManagerUserId: [],
        sensorIds: [],
        factoryId: [],
      },
      isShowToolTip: '',
      mainManagerUserList: [],
      subManagerUserList: [],
    };
  },
  computed: {
    ...mapState('users', ['currentCompany', 'myInfo']),
    ...mapGetters('users', ['isManufacturerComp', 'isFactoryComp']),
    isFilledAllRequiredField() {
      let isFilledAll = true;

      Object.values(this.equipmentInfo).forEach((info) => {
        if (
          !['equipmentSelfState', 'equipmentState'].includes(info.key) &&
          info.isRequired &&
          ((Array.isArray(this.equipmentData[info.key]) && this.equipmentData[info.key].length === 0) ||
            !this.equipmentData[info.key])
        ) {
          isFilledAll = false;
        }
      });

      if (this.isManufacturerComp && !this.equipmentData['factoryId']) {
        isFilledAll = false;
      }

      return isFilledAll;
    },
  },
  created() {
    this.initRenderData();
    document.body.addEventListener('click', (e) => {
      e.stopPropagation();
      this.isShowToolTip = '';
    });
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('manufacturerEqpGroups', ['GET_MANUFACTURER_EQP_GROUPS']),
    ...mapActions('factoryEqpGroups', ['GET_FACTORY_EQP_GROUPS_SELF']),
    ...mapActions('manufacturerUsers', ['GET_MANUFACTURER_USER_NAME']),
    ...mapActions('factoryUsers', ['GET_FACTORY_USER_NAME']),
    ...mapActions('manufacturerSensors', ['GET_MANUFACTURER_SENSOR_MODEL']),
    ...mapActions('factorySensors', ['GET_FACTORY_SENSOR_MODEL']),
    ...mapActions('manufacturerEquipments', ['POST_MANUFACTURER_EQP', 'GET_MANUFACTURER_FACTORY_NAME']),
    ...mapActions('factorySelfEquipments', ['POST_FACTORY_EQP_SELF']),
    initRenderData() {
      this.equipmentData.companyId = this.myInfo.companyId;
      switch (this.currentCompany) {
        case 'M':
          this.equipmentInfo = EqpManufacturerInfo;
          this.getEquipmentGroupList();
          this.getUserNameList();
          this.getSensorNameList();
          this.getFactoryNameList();
          break;
        case 'F':
          this.equipmentInfo = EqpFactoryInfo;
          this.getSelfEquipmentGroupList();
          this.getSelfUserNameList();
          this.getSelfSensorNameList();
          break;
      }
    },
    async getEquipmentGroupList() {
      this.dropdownList.equipmentGroupId = await this.GET_MANUFACTURER_EQP_GROUPS(['', '']);
    },
    async getUserNameList() {
      this.dropdownList.equipmentManagerIds = await this.GET_MANUFACTURER_USER_NAME();
    },
    async getSensorNameList() {
      this.dropdownList.sensorIds = await this.GET_MANUFACTURER_SENSOR_MODEL();
    },
    async getSelfEquipmentGroupList() {
      this.dropdownList.equipmentGroupId = await this.GET_FACTORY_EQP_GROUPS_SELF();
    },
    async getSelfUserNameList() {
      const userNameList = await this.GET_FACTORY_USER_NAME();
      this.dropdownList.mainManagerUserId = userNameList;
      this.dropdownList.subManagerUserId = userNameList;
      this.mainManagerUserList = userNameList;
      this.subManagerUserList = userNameList;
    },
    async getSelfSensorNameList() {
      this.dropdownList.sensorIds = await this.GET_FACTORY_SENSOR_MODEL();
    },
    async getFactoryNameList() {
      this.dropdownList.factoryId = await this.GET_MANUFACTURER_FACTORY_NAME();
    },
    toggleToolTip(equipKey) {
      this.isShowToolTip = equipKey;
    },
    onSaveInputValue(inputKey, changedValue) {
      this.equipmentData[inputKey] = changedValue;
    },
    async onSaveEquipment() {
      switch (this.currentCompany) {
        case 'M':
          await this.POST_MANUFACTURER_EQP(this.equipmentData);
          break;
        case 'F':
          await this.POST_FACTORY_EQP_SELF(this.equipmentData);
          break;
      }
      this.onMoveEquipmentList();
    },
    onMoveEquipmentList() {
      this.$router.push(`/equipments`).catch((err) => err);
    },
    onSetSelectedIdList(contentIdList, selectedName, id) {
      switch (id) {
        case 'equipmentGroupId':
          this.equipmentData.equipmentGroupId = contentIdList[0];
          break;
        case 'equipmentManagerIds':
          this.equipmentData.equipmentManagerIds = contentIdList;
          break;
        case 'mainManagerUserId':
          this.equipmentData.mainManagerUserId = contentIdList[0];
          this.dropdownList.subManagerUserId = this.filteredDropdownList(this.subManagerUserList, selectedName);
          break;
        case 'subManagerUserId':
          this.equipmentData.subManagerUserId = contentIdList[0];
          this.dropdownList.mainManagerUserId = this.filteredDropdownList(this.mainManagerUserList, selectedName);
          break;
        case 'sensorIds':
          this.equipmentData.sensorIds = contentIdList;
          break;
        case 'factoryId':
          this.equipmentData.factoryId = contentIdList[0];
      }
    },
    filteredDropdownList(list, selectedName) {
      return list.filter((managerData) => managerData.name !== selectedName);
    },
  },
  components: { MainSectionHeader, Subtitle, ListBottomButtonBox, DetailListRow },
};
</script>

<style scoped lang="scss">
@import 'EquipmentRegister';
</style>
