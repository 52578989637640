var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-white has-no-scroll" },
    [
      _c("main-section-header", {
        attrs: { title: _vm.title[_vm.currentCompany] },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("subtitle", { attrs: { subtitle: "설비 정보" } }),
          _c(
            "ul",
            { staticClass: "register-list" },
            _vm._l(_vm.equipmentInfo, function (equipInfo, index) {
              return _c(
                "detail-list-row",
                {
                  key: index,
                  attrs: {
                    "is-register": true,
                    info: equipInfo,
                    inputKey: equipInfo.key,
                    "input-value": _vm.equipmentData[equipInfo.key],
                    maxlength: equipInfo.maxlength,
                    placeholder: equipInfo.placeholder,
                    "dropdown-list": _vm.dropdownList[equipInfo.key],
                  },
                  on: {
                    saveChangeValue: _vm.onSaveInputValue,
                    setSelectedIdList: _vm.onSetSelectedIdList,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "label",
                      class: { "label-required": equipInfo.isRequired },
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _vm._v(" " + _vm._s(equipInfo.label) + " "),
                      equipInfo.additionalInfo
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "tool-tip-button",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleToolTip(equipInfo.key)
                                  },
                                },
                              },
                              [
                                _c("vue-material-icon", {
                                  attrs: { name: "info", size: 12 },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "tool-tip",
                                    class: {
                                      "tool-tip-show":
                                        _vm.isShowToolTip === equipInfo.key,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(equipInfo.additionalInfo) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            }),
            1
          ),
          _vm.isManufacturerComp
            ? [
                _c("subtitle", { attrs: { subtitle: "납품 정보" } }),
                _c(
                  "ul",
                  { staticClass: "register-list" },
                  _vm._l(_vm.deliveryEquipInfo, function (deliveryInfo, index) {
                    return _c(
                      "detail-list-row",
                      {
                        key: index,
                        attrs: {
                          "is-register": true,
                          info: deliveryInfo,
                          inputKey: deliveryInfo.key,
                          "input-value": _vm.equipmentData[deliveryInfo.key],
                          placeholder: deliveryInfo.placeholder,
                          "dropdown-list": _vm.dropdownList[deliveryInfo.key],
                        },
                        on: {
                          saveChangeValue: _vm.onSaveInputValue,
                          setSelectedIdList: _vm.onSetSelectedIdList,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "label",
                            class: {
                              "label-required": deliveryInfo.isRequired,
                            },
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [
                            _vm._v(" " + _vm._s(deliveryInfo.label) + " "),
                            deliveryInfo.additionalInfo
                              ? [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "tool-tip-button",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleToolTip(
                                            deliveryInfo.key
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("vue-material-icon", {
                                        attrs: { name: "info", size: 12 },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "tool-tip",
                                          class: {
                                            "tool-tip-show":
                                              _vm.isShowToolTip ===
                                              deliveryInfo.key,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                deliveryInfo.additionalInfo
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "list-bottom-button-box",
            [
              _c(
                "button-basic",
                {
                  attrs: { slot: "button", color: "gray-border-1", width: 120 },
                  on: { click: _vm.onMoveEquipmentList },
                  slot: "button",
                },
                [_vm._v("취소")]
              ),
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "primary",
                    width: 120,
                    disabled: !_vm.isFilledAllRequiredField,
                  },
                  on: { click: _vm.onSaveEquipment },
                  slot: "button",
                },
                [_vm._v(" 등록 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }